export function addConnectionDependency(dependency) {
  return {
    type: "ADD_CONNECTION_DEPENDENCY",
    id: dependency.module_name,
    payload: dependency,
  }
}

export function addMainService(service) {
  return {
    type: "ADD_MAIN_SERVICE",
    payload: service,
  }
}

export function setApplet(applet) {
  return {
    type: "SET_APPLET",
    payload: applet,
  }
}

export function updateAppletConfigurations(configurations) {
  return {
    type: "UPDATE_APPLET_CONFIGURATIONS",
    payload: configurations,
  }
}

export function updateAppletEnablementRules(rules) {
  return {
    type: "UPDATE_APPLET_ENABLEMENT_RULES",
    payload: rules,
  }
}

export function setUser(user) {
  return {
    type: "SET_USER",
    payload: user,
  }
}

export function upgradeUserSubscription(user) {
  return {
    type: "USER_UPGRADE",
    payload: user,
  }
}

export function setConfig(config) {
  return {
    type: "SET_CONFIG",
    payload: config,
  }
}

export function setActivationUrl(bulkActivationUrl) {
  return {
    type: "SET_ACTIVATION_URL",
    payload: bulkActivationUrl,
  }
}

export function accessGrantedClicked(authenticationUrl) {
  return {
    type: "ACCESS_GRANT_CLICKED",
    payload: authenticationUrl,
  }
}

export function connectClicked(status) {
  return {
    type: "CONNECT_CLICKED",
    payload: status,
  }
}

export function resetConnectClicked(status) {
  return {
    type: "RESET_CONNECT_CLICKED",
    payload: status,
  }
}

export function disconnectClicked(status) {
  return {
    type: "DISCONNECT_CLICKED",
    payload: status,
  }
}

export function appletIsSaving(status) {
  return {
    type: "APPLET_SAVING",
    payload: status,
  }
}

export function appletEnabled(status) {
  return {
    type: "APPLET_ENABLED",
    payload: status,
  }
}

export function appletUpdated(status) {
  return {
    type: "APPLET_UPDATED",
    payload: status,
  }
}

export function updateNextToBeConnected(nextToBeConnected) {
  return {
    type: "UPDATE_NEXT_TO_BE_CONNECTED",
    payload: nextToBeConnected,
  }
}

export function requestUserAuth() {
  return {
    type: "USER_AUTH_REQUESTED_AT",
    payload: new Date().toISOString(),
  }
}

export function resetAuthRequested() {
  return {
    type: "USER_AUTH_REQUESTED_AT",
    payload: null,
  }
}

export function setError(error) {
  return {
    type: "SET_ERROR",
    payload: error,
  }
}

export function createApplet(createRequested) {
  return {
    type: "CREATE_APPLET",
    payload: createRequested,
  }
}

export function appletCreated(created) {
  return {
    type: "APPLET_CREATED_SUCCESS",
    payload: created,
  }
}

export function appletDisabled(status) {
  return {
    type: "APPLET_DISABLED_SUCCESS",
    payload: status,
  }
}

export function setLastServiceConnected(service) {
  return {
    type: "LAST_SERVICE_CONNECTED",
    payload: service,
  }
}

export function clearLocalStorage(status) {
  return {
    type: "CLEAR_LOCAL_STORAGE",
    payload: status,
  }
}

export function updateAnimationCollection(animation) {
  return {
    type: "UPDATE_ANIMATION_COLLECTION",
    id: animation.animation,
    payload: animation.status,
  }
}
