// This reducer manages the state of animations throughout the flow
// so that if the user refreshes the browser or navigates back to it
// we don't replay the animations
const initialState = {
  collection: {
    connectClicked: { played: false },
    showSuccessConnection: { played: false },
    enabled: { played: false },
    disabled: { played: false },
  },
}

export default function animation(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_ANIMATION_COLLECTION": {
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.id]: action.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}
