import { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

function Errors({ errors, isOnColor, includeCustomerSupportLink }) {
  const className = classNames("inline-errors", {
    "inline-errors-on-color": isOnColor,
  })

  const handleCustomerSupport = e => {
    zE("webWidget", "open")
    e.preventDefault()
  }

  const customerSupportLink = () => {
    return (
      <span>
        Please contact{" "}
        <a
          href="mailto:platform-support+billing@ifttt.com"
          onClick={handleCustomerSupport}
          className="link-inline"
        >
          our customer support.
        </a>
      </span>
    )
  }

  return (
    <ul className={className}>
      {errors &&
        errors.map((error, i) => (
          <li key={i}>
            {error} {includeCustomerSupportLink ? customerSupportLink() : null}
          </li>
        ))}
    </ul>
  )
}

export default function InlineErrors({
  animate,
  errors,
  isOnColor,
  includeCustomerSupportLink,
}) {
  let hasErrors = !!(errors && errors.length)

  const containerRef = useRef(null)
  let [errorsToDisplay, setErrorsToDisplay] = useState(errors)

  if (
    hasErrors &&
    (!errorsToDisplay ||
      JSON.stringify(errorsToDisplay) !== JSON.stringify(errors))
  ) {
    setErrorsToDisplay(errors)
  }

  useEffect(() => {
    if (!containerRef.current) return

    if (hasErrors) {
      containerRef.current.style.display = "block"
      containerRef.current.style.height = "auto"
    } else {
      containerRef.current.style.display = "none"
      containerRef.current.style.height = "0"
    }
  }, [hasErrors])

  if (animate) {
    return (
      <div ref={containerRef} className="hidden">
        <Errors
          errors={errorsToDisplay}
          isOnColor={isOnColor}
          includeCustomerSupportLink={includeCustomerSupportLink}
        />
      </div>
    )
  } else if (hasErrors) {
    // static, non-animated
    return (
      <Errors
        errors={errors}
        isOnColor={isOnColor}
        includeCustomerSupportLink={includeCustomerSupportLink}
      />
    )
  } else {
    return null
  }
}

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  isOnColor: PropTypes.bool,
  includeCustomerSupportLink: PropTypes.bool,
}

InlineErrors.propTypes = {
  animate: PropTypes.bool,
  ...Errors.propTypes,
}

InlineErrors.defaultProps = {
  animate: false,
  errors: [],
  isOnColor: false,
  includeCustomerSupportLink: false,
}

/**
 * Find and scroll to the first visible instance of `<InlineErrors>` on the
 * page (leaving a tiny bit of breathing room above it).
 */
InlineErrors.scrollToFirstInstance = function () {
  const offset = document
    .querySelector(".inline-errors")
    ?.getBoundingClientRect()

  if (offset && offset.top > 0) {
    window.scrollTo({
      top: offset.top - 5,
      behavior: "smooth",
    })
    InlineErrors.scrollWithDelayCounter = 0
  } else {
    InlineErrors.scrollWithDelay()
  }
}
// Retry is done because on mobile phones there is a lag
// on rendering errors element and we need to wait for it to appear.
InlineErrors.scrollWithDelayCounter = 0
InlineErrors.scrollWithDelay = function () {
  if (InlineErrors.scrollWithDelayCounter > 3) {
    InlineErrors.scrollWithDelayCounter = 0
    return
  }
  InlineErrors.scrollWithDelayCounter++
  window.setTimeout(InlineErrors.scrollToFirstInstance, 10)
}
