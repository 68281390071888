import { graphQueryBang, gql } from "shared/lib/graphql"

const abandonedCheckoutMutation = gql`
  mutation abandonedCheckoutMutation($plan_id: user_tier_type) {
    userProAbandonedCheckoutEmail(input: { plan_id: $plan_id }) {
      errors {
        attribute
        message
      }
      email_scheduled
    }
  }
`

export default function scheduleAbandonedCheckoutEmail(tier) {
  graphQueryBang(abandonedCheckoutMutation, { plan_id: tier })
    .then(({ userProAbandonedCheckoutEmail }) => {
      if (userProAbandonedCheckoutEmail.errors.length)
        throw new Error(JSON.stringify(userProAbandonedCheckoutEmail.errors))
    })
    .catch(e => {
      if (window.App.allowDebugErrorMessages) console.warn(e)
    })
}
