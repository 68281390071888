export default function UpdateParam(param, newValue) {
  const params = window.location.search.replace(/(^\?)/, "").split("&")
  const reducer = (accumulator, currentValue) => {
    if (currentValue !== "") {
      let [index, value] = currentValue.split("=")
      accumulator[index] = decodeURIComponent(value)
    }

    return accumulator
  }
  let queryValues = params.reduce(reducer, {})

  if (newValue) {
    queryValues[param] = newValue
  } else {
    delete queryValues[param]
  }

  let newURI = window.location.origin + window.location.pathname
  if (Object.keys(queryValues).length) {
    const URLparams = new URLSearchParams(Object.entries(queryValues))
    newURI += "?" + URLparams
  }

  if (newURI !== window.location.href) {
    window.history.replaceState(window.history.state, document.title, newURI)
  }
}
