import { graphQueryBang, graphQuery, gql } from "shared/lib/graphql"

export const SubscriptionUpdateMutation = gql`
  mutation SubscriptionUpdateMutation($product_id: String!) {
    userSubscriptionUpdate(input: { product_id: $product_id }) {
      user_subscription {
        plan_id
      }
      errors {
        attribute
        message
      }
    }
  }
`

type updateSubscriptionPlanProps = {
  productId: string
}

export const updateSubscriptionPlan = async ({ productId }: updateSubscriptionPlanProps) => {
  const graphConfigs = { awaitClearCache: true }
  const response = await graphQueryBang(
    SubscriptionUpdateMutation,
    {
      product_id: productId,
    },
    graphConfigs
  )
  return response.userSubscriptionUpdate
}

export const loadSubscriptionDetails = async (url: string) => {
  return fetch(url, {
    headers: { "Content-Type": "application/json" },
  }).then(response => response.json())
}
