import { graphQueryBang, gql } from "shared/lib/graphql"

const NormalizedAppletUpdateMutation = gql`
  mutation NormalizedAppletUpdate(
    $appletId: String!
    $failedAppletRunNotificationsEnabled: Boolean
    $pushEnabled: Boolean
    $name: String
  ) {
    normalizedAppletUpdate(
      input: {
        applet_id: $appletId
        failed_applet_run_notifications_enabled: $failedAppletRunNotificationsEnabled
        push_enabled: $pushEnabled
        name: $name
      }
    ) {
      errors {
        attribute
        message
      }
    }
  }
`

export const updateApplet = async ({ appletId, pushEnabled, name, failedAppletRunNotificationsEnabled }) => {
  const { normalizedAppletUpdate } = await graphQueryBang(NormalizedAppletUpdateMutation, {
    appletId,
    pushEnabled,
    name,
    failedAppletRunNotificationsEnabled,
  })

  return normalizedAppletUpdate
}

const userAndEnablementRulesQuery = gql`
  query userAndEnablementRulesQuery($applet_id: String!) {
    me {
      login
      eligible_for_trial
      applet_quota_slots_total
      applet_quota_slots_remaining
      tier
    }
    applet(id: $applet_id) {
      normalized_applet {
        enablement_rules {
          block_user
          minimum_tier
          block_reason
        }
      }
    }
  }
`

export const reloadSensitiveDataAfterUpgrade = async appletId => {
  return await graphQueryBang(userAndEnablementRulesQuery, { applet_id: appletId })
}
