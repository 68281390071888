import icon from "images/icons/icon-delay.svg"
import parse from "html-react-parser"

interface Props {
  title: string
}

export default function DelayIcon({ title }: Props) {
  return <span className="svg-icon" title={title}>{parse(icon)}</span>
}
