import { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import {
  sendAppletFeedback,
  fetchAppletFeedbackByUser,
} from "shared/lib/graphql"

import "./applet_feedback.scss"

const opinions = [
  "Not useful",
  "Too slow",
  "Does not work as expected",
  "Other",
]

const opinionCodes = ["not_useful", "too_slow", "unexpected", "other"]

export default class AppletFeedback extends Component {
  state = {
    like: null,
    opinionCode: "",
    suggestionText: "",
    submitted: false,
    backgroundSubmitted: false,
    userRating: null,
  }

  componentDidMount() {
    fetchAppletFeedbackByUser(this.props.appletId)
      .then(data => {
        this.setState({ userRating: data.applet_feedback_by_user })
      })
      .catch(err => console.log(err))
  }

  postPartialFeedback = () => {
    const { userRating } = this.state
    const { like, submitted, backgroundSubmitted } = this.state
    if (
      (userRating === null || userRating !== like) &&
      !submitted &&
      !backgroundSubmitted &&
      like !== null
    ) {
      this.setState({ backgroundSubmitted: true })
      this.postFeedback(null, false /* don't mark the form submitted */)
    }
  }

  postFeedback = (e, markSubmitted = true) => {
    if (e) e.preventDefault()
    const { like, opinionCode, suggestionText } = this.state

    sendAppletFeedback(
      this.props.appletId,
      like,
      opinionCode,
      suggestionText.trim().slice(0, 255)
    )
      .then(() => {
        if (markSubmitted) this.setState({ submitted: true })
      })
      .catch(err => console.log(err))
  }

  handleLike = rating => {
    this.setState(
      {
        like: rating,
        opinionCode: "",
        suggestionText: "",
      },
      () => {
        // always post the initial response in case the form is not submitted
        this.postPartialFeedback()
        const { bottom } = this.feedbackRef.getBoundingClientRect()
        if (bottom > window.innerHeight) {
          this.feedbackRef.scrollIntoView({
            behavior: "smooth",
            block: "center",
          })
        }
      }
    )
  }

  setOpinionCode = index => {
    this.setState({ opinionCode: opinionCodes[index] })
  }

  updateSuggestionText = event => {
    this.setState({ suggestionText: event.target.value })
  }

  feedbackDialogue() {
    const { like } = this.state

    return (
      <>
        <p className="txt-body-3">Do you like this Applet?</p>
        <div styleName="answers">
          <button
            onClick={() => this.handleLike(true)}
            styleName="option"
            className={classnames("button-tertiary", {
              "button-outlined": !like,
            })}
          >
            Yes
          </button>
          <button
            onClick={() => this.handleLike(false)}
            styleName="option"
            className={classnames("button-tertiary", {
              "button-outlined": like == null || like === true,
            })}
          >
            No
          </button>
        </div>
        {like !== null && (
          <div styleName="dialogue">
            <form styleName="applet-feedback-form" onSubmit={this.postFeedback}>
              {like === false
                ? this.renderMultipleChoice()
                : this.renderSuggestionBox(true)}
            </form>
          </div>
        )}
      </>
    )
  }

  renderMultipleChoice() {
    return (
      <>
        {opinions.map((code, index) => (
          <div key={index}>
            <div styleName="form-option">
              <input
                type="radio"
                value={code}
                key={index}
                onClick={() => this.setOpinionCode(index)}
                checked={this.state.opinionCode === opinionCodes[index]}
                id={"applet_feedback_option_" + index}
              />
              <label
                htmlFor={"applet_feedback_option_" + index}
                className="txt-body-3"
              >
                {code}
              </label>
            </div>
            {this.state.opinionCode === opinionCodes[index] &&
              this.renderSuggestionBox()}
          </div>
        ))}
        {this.state.opinionCode && this.renderSubmit()}
      </>
    )
  }

  renderSubmit() {
    return (
      <>
        <input
          className="button-tertiary"
          styleName="submit-button"
          type="submit"
          value="Submit"
        />
      </>
    )
  }

  renderSuggestionBox(renderSubmitButton) {
    let charCount = this.state.suggestionText.length

    return (
      <>
        {this.state.like && (
          <div styleName="prompt">
            <p className="txt-body-3">Thank you!</p>
            <p className="long-txt-body-3">
              What did you like most about this Applet?
            </p>
          </div>
        )}
        <div styleName="textarea-wrapper">
          <textarea
            value={this.state.suggestionText}
            onChange={this.updateSuggestionText}
            placeholder="Tell us more..."
            maxLength="255"
          />
          <span styleName="text-counter">{charCount} / 255</span>
        </div>
        {renderSubmitButton ? this.renderSubmit() : <></>}
      </>
    )
  }

  renderThanks() {
    const sentiment = this.state.like
      ? "Your suggestion was sent to the creator. Thanks for taking the time to help them improve."
      : "We appreciate your feedback."

    return (
      <>
        <div styleName="thanks">
          <p className="txt-body-3">Thank you!</p>
          <p className="long-txt-body-3">{sentiment}</p>
        </div>
      </>
    )
  }

  render() {
    const { submitted, userRating } = this.state
    return (
      <div className="feedback-container" ref={ref => (this.feedbackRef = ref)}>
        <div styleName="feedback-ui">
          {userRating === null &&
            (submitted ? this.renderThanks() : this.feedbackDialogue())}
        </div>
      </div>
    )
  }
}

AppletFeedback.propTypes = {
  appletId: PropTypes.string,
  serviceName: PropTypes.string,
}
