import { forwardRef, useEffect, useImperativeHandle } from "react"
import { CardElement } from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import FormField from "app/components/sdk/form_field"

import colors from "foundation/_colors_export.scss?variables"
import typography from "foundation/_typography_export.scss?variables"
import responsive from "foundation/_responsive_export.scss?variables"

// above this size the CC # is partially hidden after entry
const DEFAULT_FONT_SIZE = "22.2px"

export const CardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: colors.primaryTextColor,
      fontFamily: typography["avenir"],
      fontSize: DEFAULT_FONT_SIZE,
      fontWeight: 600,
      "::placeholder": {
        color: "#ccc",
      },
    },
    invalid: {
      color: "#ee4433",
      iconColor: "#ee4433",
    },
  },
}

const noop = () => {}

const CreditCardField = forwardRef(({ onCardReadyChange, errors }, ref) => {
  useEffect(() => {
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth
    let fontSize

    if (windowWidth < 370) {
      fontSize = "14.4px"
      // ^ our regular font size makes the card numbers overlap the exp data and cvc
    } else if (windowWidth < parseInt(responsive["screenXsMin"])) {
      fontSize = "16.8px"
      // ^ *
    } else if (windowWidth < parseInt(responsive["screenSmMin"])) {
      fontSize = "19.2px"
    } else if (windowWidth < parseInt(responsive["screenLgMin"])) {
      fontSize = "21.6px"
    } else {
      fontSize = DEFAULT_FONT_SIZE
    }

    CardElementOptions.style.base.fontSize = fontSize
  }, [])

  const autoFocus = element => {
    // Avoid run conflict on Cypress.
    if (!window.App.integrationTestMode) element.focus()
  }

  useImperativeHandle(ref, () => ({
    getElement: () => {
      return CardElement
    },
  }))

  return (
    <div className="credit-card-field stripe">
      <FormField
        name="cardnumber"
        errors={errors?.cardnumber}
        animateErrors={true}
        aria-label="credit card field"
      >
        <CardElement
          options={CardElementOptions}
          className="input"
          onChange={event => onCardReadyChange(event.complete)}
          onReady={autoFocus}
        />
      </FormField>
    </div>
  )
})

CreditCardField.propTypes = {
  onCardReadyChange: PropTypes.func,
  errors: PropTypes.object,
}

CreditCardField.defaultProps = {
  onCardError: noop,
  onCardReady: noop,
  errors: {},
}

CreditCardField.displayName = "CreditCardField"
export default CreditCardField
