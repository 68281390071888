import PropTypes from "prop-types"
import Cookies from "js-cookie"
import { capitalize } from "humanize-plus"

import { rejectedXHRPromiseLogger } from "shared/lib/xhr_promise"
import appletOrConnection from "shared/lib/use_applet_or_connection"
import { clearState } from "shared/localstorage_helper"

import { deleteLiveAppletMutation } from "./config/api"
import { actionLabel } from "./utils"

const ArchiveDelete = ({ applet, myAppletURL, sessionId, user }) => {
  const configType = appletOrConnection(applet)
  const action = actionLabel(applet, user)

  const handleArchive = e => {
    e.preventDefault()

    if (!confirm("Are you sure you want to continue?")) {
      return
    }

    const success = () => {
      const localStorageId = `connectionId-${applet.id}-${sessionId}`
      clearState(localStorageId)

      Cookies.remove(`mobile_alert_${user.login}_${applet.id}`)

      window.appendFlash(`${capitalize(configType)} was ${action.past.toLowerCase()}`, "success", () => {
        window.location.assign(myAppletURL)
      })
    }

    const fail = rejectedXHRPromiseLogger(() => {
      window.appendFlash(`There was an error ${action.infinitive.toLowerCase()} this ${configType}`, "danger")
    })

    deleteLiveAppletMutation(applet.id).then(success).catch(fail)
  }

  if (["never_enabled_for_user", null].includes(applet?.status) && (!user.id || user.id !== applet.author_id)) {
    return null
  }

  return (
    <>
      <div className="delete">
        <a role="button" onClick={handleArchive}>
          {action.label}
        </a>
      </div>
    </>
  )
}

ArchiveDelete.propTypes = {
  applet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string,
    type: PropTypes.string.isRequired,
    can_push_enable: PropTypes.bool.isRequired,
    author_id: PropTypes.string,
    push_enabled: PropTypes.bool,
  }).isRequired,
  myAppletURL: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    login: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
}

export default ArchiveDelete
