const paidBenefits = {
  pro: [
    "Unlimited Applets",
    "Queries and filter code",
    "AI and other exclusive services",
    "Increased rate limits and speeds",
    "Multi-action Applets",
    "Prioritized customer support",
  ],

  intermediate_pro: [
    "20 Applets",
    "Faster Applet speeds",
    "Multi-action Applets",
    "Increased rate limits",
    "Exclusive Pro services",
    "Customer support",
  ],
}

export default paidBenefits
