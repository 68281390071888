import connections from "./connections"
import applet from "./applet"
import config from "./config"
import user from "./user"
import error from "./error"
import animation from "./animation"

const reducers = {
  connections,
  applet,
  user,
  config,
  error,
  animation,
}

export default reducers
