import { loadStripe } from "@stripe/stripe-js/pure"
import { Elements } from "@stripe/react-stripe-js"
import PropTypes from "prop-types"

export default function StripeJsContainer({ children }) {
  const stripePromise = loadStripe(window.App.stripePublishableKey)

  // Usage of 'stripe' and 'elements' must be in a child component of <Elements>
  return <Elements stripe={stripePromise}>{children}</Elements>
}

StripeJsContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
