import { useState } from "react"
import StepDetails from "./step_details"
import { formatToTimestamp } from "app/components/diy_composer/utils/format_time"

interface TQADetails {
  name: string
  description: string
  module_name: string | ""
  channel: {
    brand_color: string
    monochrome_image_url: string
    name: string | ""
    module_name: string | ""
    published: boolean
  }
}
interface AppletTrigger {
  trigger: TQADetails
}

interface AppletActions {
  action: TQADetails
}

interface AppletQueries {
  query: TQADetails
}

interface Props {
  details: {
    applet_actions: Array<AppletActions>
    applet_queries: Array<AppletQueries>
    applet_trigger: AppletTrigger
    normalized_applet: {
      actions_delay: number
    }
    filter_code: string
  }
  enabledForUser: boolean
  showAppletDetails: boolean
}

const AppletDetails = ({ details, enabledForUser, showAppletDetails }: Props) => {
  const [showDetails, setShowDetails] = useState(showAppletDetails)

  const triggerAdded = !!details.applet_trigger
  const queriesAdded = details.applet_queries.length > 0
  const filterCodeAdded = !!details.filter_code
  const actionsAdded = details.applet_actions.length > 0
  const delayAdded = details.normalized_applet.actions_delay > 0

  const formattedDelay = () => {
    return formatToTimestamp(Number(details.normalized_applet.actions_delay))
  }

  const handleShowDetails = () => {
    setShowDetails(!showDetails)
  }

  const trigger = details.applet_trigger.trigger

  return (
    <div className="applet-details">
      <div className={showDetails ? "applet-details-wrapper" : "hide"}>
        {triggerAdded && (
          <StepDetails
            label="If"
            brandColor={`#` + trigger.channel.brand_color}
            image={trigger.channel.monochrome_image_url}
            name={trigger.name}
            serviceName={trigger.channel.name}
            serviceModuleName={trigger.channel.published ? trigger.channel.module_name : undefined}
            stepUrl={
              trigger.channel.published ? `/${trigger.channel.module_name}/triggers/${trigger.module_name}` : undefined
            }
            description={trigger.description}
          />
        )}
        {queriesAdded &&
          details.applet_queries.map(query => (
            <>
              <StepDetails
                label="With"
                brandColor={`#` + query.query.channel.brand_color}
                image={query.query.channel.monochrome_image_url}
                serviceName={query.query.name}
                serviceModuleName={query.query.channel.published ? query.query.channel.module_name : undefined}
                stepUrl={
                  query.query.channel.published
                    ? `/${query.query.channel.module_name}/queries/${query.query.module_name}`
                    : undefined
                }
                description={query.query.description}
                name="Query"
              />
            </>
          ))}
        {filterCodeAdded && (
          <StepDetails label="When" brandColor="#222222" name="Filter code" serviceName="Filter code" />
        )}
        {delayAdded && (
          <StepDetails label="Wait" brandColor="#222222" serviceName="Delay" name={`${formattedDelay()} delay`} />
        )}
        {actionsAdded &&
          details.applet_actions.map((action, i) => (
            <>
              <StepDetails
                label={i > 0 ? "And" : "Then"}
                brandColor={`#` + action.action.channel.brand_color}
                image={action.action.channel.monochrome_image_url}
                name={action.action.name}
                serviceName={action.action.channel.name}
                serviceModuleName={action.action.channel.published ? action.action.channel.module_name : undefined}
                stepUrl={
                  action.action.channel.published
                    ? `/${action.action.channel.module_name}/actions/${action.action.module_name}`
                    : undefined
                }
                description={action.action.description}
                finalStep={details.applet_actions.length === i + 1}
              />
            </>
          ))}

        {enabledForUser && <p className="connection-id">ID {details.id}</p>}

        <button className="txt-body-3" onClick={handleShowDetails}>
          Fewer details
        </button>
      </div>
      <div className={showDetails ? "hide" : "show-block"}>
        <button className="txt-body-3" onClick={handleShowDetails}>
          More details
        </button>
      </div>
    </div>
  )
}

export default AppletDetails
