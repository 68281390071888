import moment from "moment"
import { Applet } from "types"

interface ActivityProps {
  connection: Applet
  activityURL: string
}

export const Activity = ({ connection, activityURL }: ActivityProps) => {
  const { status, created_at, last_run, run_count } = connection

  if (status == "never_enabled_for_user" || !(created_at || last_run || run_count)) return null

  return (
    <>
      <div className="connection-view-activity">
        <div className="connection-list-activity">
          <p className="txt-body-3">Check the log of your Applet runs</p>
          <a role="button" className="button-tertiary button-outlined" href={activityURL}>
            View activity
          </a>
        </div>
      </div>
    </>
  )
}

interface RecentActivityProps {
  connection: Applet
}

export const RecentActivity = ({
  connection: { config_type, created_at, last_run, run_count, status },
}: RecentActivityProps) => {
  const isStaticApplet = config_type === "static"

  if (status == "never_enabled_for_user" || !(created_at || last_run || run_count)) return null

  return (
    <div className="recent-activity txt-body-3">
      {created_at && <p>Connected {moment(created_at, "YYYY-MM-DD HH:mm:ss ZZZZ").format("MMM DD, YYYY")}</p>}

      {isStaticApplet && (
        <>
          {last_run && <p>Last activity {moment(last_run, "YYYY-MM-DD HH:mm:ss ZZZZ").format("MMM DD, YYYY")}</p>}

          {run_count ? (
            <p>
              Run {run_count} time{run_count > 1 && <span>s</span>}
            </p>
          ) : (
            <p>Never run</p>
          )}
        </>
      )}
    </div>
  )
}
