import { useState, useEffect } from "react"
import { gql, graphQueryBang, myAppletsNormalizedAppletFields } from "shared/lib/graphql"
import SmallAppletCard from "shared/components/applet_card/ifttt_next/small_applet_card"
import appletOrConnectionPath from "app/scripts/applet_or_connection_path"
import { Applet } from "types"

interface Props {
  applet: Applet
  topApplets: object[] | undefined
  signedIn: boolean
}

const AppletRecommendations = ({ applet, topApplets, signedIn }: Props) => {
  const [recommendedApplets, setRecommendedApplets] = useState<object[]>([])
  const ctaText = signedIn ? "More like this" : "You might also like"

  useEffect(() => {
    const isDesktop = window.matchMedia?.(`(min-width: 850px)`)?.matches
    if (topApplets && topApplets.length > 0) {
      setRecommendedApplets(topApplets)
    } else {
      AppletRecommendations.fetchRecommendedApplets(applet.services, isDesktop)
        .then((data: any) => {
          setRecommendedApplets(data.recommended_applets_for_channels)
        })
        .catch((e: any) => window.App.allowDebugErrorMessages && console.log(e))
    }
  }, [])

  return (
    <div className="recommended-applets">
      <h3 className="cta">{ctaText}</h3>
      <ul className="web-applet-cards">
        {recommendedApplets.map((applet, i) => (
          <li className="my-web-applet-card web-applet-card" key={i}>
            <SmallAppletCard appletPath={appletOrConnectionPath(applet)} applet={applet} />
          </li>
        ))}
      </ul>
    </div>
  )
}

const recommendedAppletQuery = gql`
  query recommendedAppletQuery($moduleNames: [String!], $count: Int) {
    recommended_applets_for_channels(module_names: $moduleNames, count: $count) {
      ${myAppletsNormalizedAppletFields}
    }
  }
`

AppletRecommendations.fetchRecommendedApplets = (services: any[], isDesktop: boolean) =>
  graphQueryBang(recommendedAppletQuery, {
    moduleNames: services,
    count: isDesktop ? 6 : 3,
  })

export default AppletRecommendations
