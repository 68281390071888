import { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

import "./tooltip.scss"

export default function Tooltip({ children }) {
  const [hover, setHover] = useState(false)
  const containerEl = useRef(null)
  const tooltipEl = useRef(null)

  function onHover() {
    hover || setHover(true)
  }

  // state does not work well with event handlers, since the closure they read
  // from is captured at function definition time, when addEventListener is
  // called
  const position = { left: 0, top: 0 }
  let mouseEnterDetected = false
  let mouseOutDetected = false

  function positionTooltip(e) {
    stopEventPropagation(e)

    position.left = e.clientX
    position.top = e.clientY

    if (!mouseEnterDetected) {
      mouseEnterDetected = true

      setTimeout(() => {
        if (!hover || !tooltipEl.current) {
          return
        }

        const rect = containerEl.current.getBoundingClientRect()
        const left = position.left - rect.left
        const top = position.top - rect.top

        tooltipEl.current.style.left = `${left}px`
        tooltipEl.current.style.top = `${top}px`
        tooltipEl.current.style.visibility = "visible"
      }, 500)
    }
  }

  function stopEventPropagation(e) {
    e.stopPropagation()
  }

  function detectMouseOut() {
    if (!mouseOutDetected) {
      mouseOutDetected = true

      setTimeout(() => {
        setHover(false)
      }, 200)
    }
  }

  useEffect(() => {
    if (!hover) return

    const currentContainerEl = containerEl.current
    const currentTooltipEl = tooltipEl.current

    currentContainerEl.addEventListener("mousemove", positionTooltip)
    currentTooltipEl.addEventListener("mousemove", stopEventPropagation)
    window.addEventListener("mousemove", detectMouseOut)

    return () => {
      currentContainerEl.removeEventListener("mousemove", positionTooltip)
      currentTooltipEl.removeEventListener("mousemove", stopEventPropagation)
      window.removeEventListener("mousemove", detectMouseOut)
    }
  }, [hover])

  return (
    <div
      className="hover-area"
      styleName="hover-area"
      onMouseOver={onHover}
      ref={containerEl}
    >
      {hover && (
        <div
          ref={tooltipEl}
          styleName="tool-tip"
          style={{ visibility: "hidden" }}
        >
          {children}
        </div>
      )}
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
}
