import { Component } from "react"
import PropTypes from "prop-types"

import ConnectButton from "./connect_button"

export default class ConnectedApplet extends Component {
  render() {
    return (
      <ConnectButton
        connectButtonMaxFontSize={this.props.connectButtonMaxFontSize}
        connectClicked={this.props.connectClicked}
        connected={this.props.appletConnected}
        ctaCopy="Connected"
        knobBgColorForDarkBrands={this.props.knobBgColorForDarkBrands}
        mainService={this.props.mainService}
        onConnectClick={this.props.onDisconnectClick}
        dynamicConfig={this.props.dynamicConfig}
        showKnob
      />
    )
  }
}

ConnectedApplet.propTypes = {
  appletConnected: PropTypes.bool,
  connectButtonMaxFontSize: PropTypes.number,
  connectClicked: PropTypes.bool.isRequired,
  knobBgColorForDarkBrands: PropTypes.bool,
  mainService: PropTypes.object.isRequired,
  onDisconnectClick: PropTypes.func.isRequired,
  dynamicConfig: PropTypes.bool,
}
