const genericError = "There was a problem submitting your payment"

export default function SubscriptionHelper({
  stripe,
  subscriptionsPath,
  price,
}) {
  /**
   * @public
   * @param {{billing_details: {name: String, email: String}, card: StripeCardElement}|PaymentMethod} paymentMethodData
   * @param onSuccess
   * @param onError
   */
  async function subscribe(
    planId,
    productId,
    paymentMethodData,
    promoCode,
    onSuccess,
    onError
  ) {
    let { error, paymentMethod } = await getPaymentMethod(paymentMethodData)

    let subscription, permissions

    try {
      paymentMethod &&
        ({ error, subscription, permissions } = await createSubscription(planId, productId, paymentMethod, promoCode))
    } catch (unexpectedError) {
      console.error(unexpectedError)
      error = genericError
    }

    if (error) {
      onError(error)
    } else if (subscription?.status === "active" || subscription?.status === "trialing") {
      onSuccess(subscription, permissions, planId, promoCode)
    }
  }

  async function getPaymentMethod(data) {
    if (data.object === "payment_method") {
      return { paymentMethod: data }
    }

    return stripe.createPaymentMethod({
      type: "card",
      ...data,
    })
  }

  async function createSubscription(
    planId,
    productId,
    paymentMethod,
    promoCode
  ) {
    const request = await fetch(subscriptionsPath, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": window.App.authenticityToken,
      },
      body: JSON.stringify({
        plan_id: planId,
        product_id: productId,
        payment_method: paymentMethod.id,
        promo_code: promoCode,
        price,
      }),
      redirect: "error",
    })
    const result = await request.json()

    return subscriptionHandler(result)
  }

  async function subscriptionHandler({ error, errors, subscription, permissions }) {
    let subscriptionError = error || (errors && errors[0])

    if (
      subscription?.status === "incomplete" ||
      subscription?.pending_setup_intent
    ) {
      // https://stripe.com/docs/billing/subscriptions/fixed-price#manage-payment-authentication
      const { error, status } = await handlePaymentThatRequiresCustomerAction(
        subscription
      )

      if (status === "succeeded") {
        return refreshSubscriptionAndPermissions(subscription.subscription_id)
      } else if (error) {
        subscriptionError = error
      } else {
        throw new Error(`Unexpected status '${status}' without error 😢`)
      }
    }

    return { error: subscriptionError, subscription, permissions }
  }

  async function refreshSubscriptionAndPermissions(subscription_id) {
    const request = await fetch(subscriptionsPath + "/" + subscription_id)
    const result = await request.json()
    return subscriptionHandler(result)
  }

  async function handlePaymentThatRequiresCustomerAction(subscription) {
    let initialPaymentIntent = subscription.latest_invoice.payment_intent

    if (
      !subscription.pending_setup_intent &&
      initialPaymentIntent.status !== "requires_source_action" &&
      initialPaymentIntent.status !== "requires_action" // newer API
    ) {
      throw new Error(
        `Unexpected payment status '${initialPaymentIntent.status}' 😢`
      )
    }

    if (subscription.pending_setup_intent) {
      const { error, setupIntent } = await stripe.confirmCardSetup(
        subscription.pending_setup_intent.client_secret
      )
      return { error, status: setupIntent?.status }
    } else {
      let { error, paymentIntent } = await stripe.confirmCardPayment(
        initialPaymentIntent.client_secret,
        {
          payment_method: initialPaymentIntent.payment_method,
        }
      )
      return { error, status: paymentIntent?.status }
    }
  }

  return { subscribe }
}
