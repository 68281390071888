import { Component } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import iosSvg from "images/home/new_home/ios_download.svg"
import androidSvg from "images/home/new_home/google_play.svg"

export default class AppDownloadButton extends Component {
  render() {
    let appInfo
    const ios = {
      type: "ios",
      icon: iosSvg,
      url: this.props.appStorePath,
    }
    const android = {
      type: "android",
      icon: androidSvg,
      url: this.props.googlePlayStorePath,
    }

    if (this.props.applet.applet_type === "ios") {
      appInfo = ios
    } else if (this.props.applet.applet_type === "android") {
      appInfo = android
    } else if (this.props.userDevice === "android") {
      appInfo = android
    } else {
      appInfo = ios
    }

    return (
      <span
        onClick={() => {
          window.open(appInfo.url, "_blank")
        }}
      >
        {parse(appInfo.icon)}
      </span>
    )
  }
}

AppDownloadButton.propTypes = {
  applet: PropTypes.shape({
    id: PropTypes.string,
    applet_type: PropTypes.string,
  }),
  appStorePath: PropTypes.string.isRequired,
  googlePlayStorePath: PropTypes.string.isRequired,
  userDevice: PropTypes.string.isRequired,
}
