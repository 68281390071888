const initialState = {
  type: null,
  message: null,
}

export default function error(state = initialState, action) {
  switch (action.type) {
    case "SET_ERROR": {
      if (action.payload.type === "access_denied") {
        // Fix for `ExecJS::ProgramError: TypeError: window.appendFlash is not a function`.
        setTimeout(
          () => window.appendFlash(decodeURIComponent(action.payload.message.replace(/\+/g, " ")), "danger"),
          0
        )
      }

      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
      }
    }

    default: {
      return state
    }
  }
}
