import ServiceCard from "shared/components/service_card.js"

interface Props {
  service: object
  path: string
}

const PopularServiceCard = ({ service, path }: Props) => {
  return (
    <li className="service-card-list-item">
      <ServiceCard service={service} path={path} />
    </li>
  )
}

export default PopularServiceCard
