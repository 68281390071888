import CheckoutModal from "./checkout_modal/index"
import MobileWarning from "./mobile_warning/index"
import ProUpgradeModal from "./pro_upgrade/index"
import modalCopy, { CheckoutStep } from "./modal_copy"

export interface URLs {
  modalSubscriptionUrl: string
  newProSubscriptionUrl: string
  newProPlusSubscriptionUrl: string
}
export interface User {
  eligibleForTrial: boolean
  isTrialing: boolean
  eligibleTrialPeriod: number
  subscriptionPaymentType: string
  tier: string
}
interface Props {
  appletFeatureFlags: {
    intermediate_pro_features: boolean
    pro_features: boolean
  }
  initialPromoCode: string
  onClose: () => void
  onUpgrade: (checkoutModalTarget: string) => void
  premiumService?: string
  proPlusProductId: string
  step: CheckoutStep
  tierRequirement: string
  urls: URLs
  user: User
  visible: boolean
}

const UpgradePlanModal = (props: Props) => {
  const { appletFeatureFlags, onClose, premiumService, proPlusProductId, step, tierRequirement, user, visible } = props
  const canUpgrade = user.subscriptionPaymentType === "stripe"
  const upgradingExistingSubscription = user.tier !== "free"

  const modalCopyObj = modalCopy({
    appletFeatureFlags,
    currentTier: user.tier,
    eligibleForTrial: user.eligibleForTrial,
    eligibleTrialPeriod: user.eligibleTrialPeriod,
    isTrialing: user.isTrialing,
    premiumService,
    step,
    tierRequirement,
  })

  return upgradingExistingSubscription ? (
    canUpgrade ? (
      <ProUpgradeModal
        onClose={onClose}
        onUpgrade={props.onUpgrade}
        proPlusProductId={proPlusProductId}
        user={user}
        visible={visible}
        modalCopyObj={modalCopyObj}
      />
    ) : (
      <MobileWarning onClose={onClose} visible={visible} />
    )
  ) : (
    <CheckoutModal {...props} modalCopyObj={modalCopyObj} />
  )
}

export default UpgradePlanModal
