import { useState } from "react"
import { updateApplet } from "./api"

import PushNotifications from "./config/push_notifications"
import { Activity, RecentActivity } from "./config/activity"
import { Applet } from "types"
import "./top_level_config.scss"

interface TopLevelConfigProps {
  applet: Applet
  showPublishCta: boolean
  urls: {
    activityPath: string
    publishedAppletEditPath: string
  }
  webhookUrl: string
}

const TopLevelConfig = ({ applet, urls, showPublishCta, webhookUrl }: TopLevelConfigProps) => {
  const { id: appletId, can_push_enable, push_enabled } = applet
  const [pushEnabled, setPushEnabled] = useState(push_enabled)
  const [failedNotificationEnabled, setFailedNotificationEnabled] = useState(
    applet.appletDetails.normalized_applet.failed_applet_run_notifications_enabled
  )
  const [finishedCopy, setFinishedCopy] = useState(false)

  const onPushChange = async (pushEnabled: boolean) => {
    await updateApplet({ appletId, pushEnabled })

    setPushEnabled(pushEnabled)
  }

  const onFailedNotificationChange = async (failedAppletRunNotificationsEnabled: boolean) => {
    await updateApplet({ appletId, failedAppletRunNotificationsEnabled })

    setFailedNotificationEnabled(failedAppletRunNotificationsEnabled)
  }

  const copyToClipboard = (e, text) => {
    e.preventDefault()
    navigator.clipboard.writeText(text)
    setFinishedCopy(true)
    setTimeout(() => {
      setFinishedCopy(false)
    }, 1000)
  }

  return (
    <>
      <RecentActivity connection={applet} />

      {webhookUrl && (
        <div styleName="webhook-info">
          <p className="txt-body-3" styleName="title">
            Your webhook URL
          </p>
          <div styleName="webhook-url-container" onClick={e => copyToClipboard(e, webhookUrl)}>
            <div styleName="webhook-url">{webhookUrl}</div>
            <div styleName="copy-mask">
              <button styleName="copy-button">
                <div styleName="icon" />
                <div styleName={`pre-copy-text ${finishedCopy ? "hide" : ""}`}>Copy</div>
                <div styleName={`post-copy-text ${finishedCopy ? "active" : ""}`}>Copied</div>
              </button>
            </div>
          </div>

          <p styleName="description">
            This is your custom webhook URL to make a POST or GET web request to. Please configure your application with
            this URL.
          </p>
        </div>
      )}

      {applet.status == "enabled_for_user" && (
        <div className="connection-notifications-wrapper">
          {can_push_enable && (
            <PushNotifications attribute={pushEnabled} label="Notify me when this runs" onChange={onPushChange} />
          )}

          <PushNotifications
            attribute={failedNotificationEnabled}
            label="Notify me if this fails to run"
            onChange={onFailedNotificationChange}
          />
        </div>
      )}

      <Activity connection={applet} activityURL={urls.activityPath} />

      {showPublishCta ? (
        <div className="connection-view-activity">
          <div className="connection-list-activity">
            <p className="txt-body-3">Publish this Applet so anyone can use it.</p>

            <a role="button" className="button-tertiary button-outlined" href={urls.publishedAppletEditPath}>
              Publish
            </a>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default TopLevelConfig
