import Modal from "shared/components/modal"

import "../shared_modal.scss"

interface MobileWarningProps {
  onClose: () => void
  visible: boolean
}

const MobileWarning = ({ onClose, visible }: MobileWarningProps) => {
  return (
    <div styleName="modal-container">
      <Modal show={visible} closeListener={onClose} includeCloseX={false}>
        <h2>Upgrade with the IFTTT app</h2>
        <p>Your mobile subscription can&#39;t be updated on the web. You can upgrade your plan using the IFTTT app.</p>
        <div className="action-buttons-container">
          <button className="button-tertiary cancel-link" onClick={onClose}>
            Close
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default MobileWarning
