import PropTypes from "prop-types"

import InlineErrors from "shared/components/inline_errors"

export default function FormField({
  children,
  label,
  value,
  defaultValue,
  inputRef,
  noHelp,
  errors,
  animateErrors,
  includeCustomerSupportLink,
  ariaLabel,
  ...otherInputProps
}) {
  const inputProps = {
    ref: inputRef,
    ...otherInputProps,
  }
  if (defaultValue || inputRef) {
    inputProps.defaultValue = defaultValue || ""
  } else {
    inputProps.value = value || ""
    if (!inputProps.onChange) {
      inputProps.readOnly = true
    }
  }
  if (noHelp) {
    Object.assign(inputProps, {
      autoCapitalize: "off",
      autoComplete: "off",
      autoCorrect: "off",
      spellCheck: "off",
    })
  }

  return (
    <div className="field" aria-label={ariaLabel || label}>
      {label && (
        <div className="label">
          <label htmlFor={inputProps.name}>{label}</label>
        </div>
      )}
      <InlineErrors
        errors={errors}
        animate={animateErrors}
        includeCustomerSupportLink={includeCustomerSupportLink}
      />
      {children || (
        <div className="input">
          <input {...inputProps} />
        </div>
      )}
    </div>
  )
}

FormField.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inputRef: PropTypes.object,
  noHelp: PropTypes.bool, // sets the following properties to "off
  autoCapitalize: PropTypes.string,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.string,
  spellCheck: PropTypes.string,
  autoFocus: PropTypes.bool,
  pattern: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string.isRequired),
  animateErrors: PropTypes.bool,
  includeCustomerSupportLink: PropTypes.bool,
}

FormField.defaultProps = {
  type: "text",
  required: false,
  disabled: false,
  includeCustomerSupportLink: false,
}
