export default function AnimationEvent() {
  let animation
  const el = document.createElement("fakeelement")

  const animations = {
    animation: "animationend",
    OAnimation: "oAnimationEnd",
    MozAnimation: "animationend",
    WebkitAnimation: "webkitAnimationEnd",
  }

  for (animation in animations) {
    if (el.style[animation] !== undefined) {
      return animations[animation]
    }
  }
}
