import { useEffect, useState } from "react"

import { updateSubscriptionPlan, loadSubscriptionDetails } from "./api"

import Modal from "shared/components/modal"
import { saveUserPermissions } from "shared/scripts/new_badge_button"

import "../shared_modal.scss"

interface User {
  isTrialing: boolean
}
interface ProUpgradeModalProps {
  modalCopyObj: {
    copy: string
    editAppletWarning: string
    title: string
  }
  onClose: () => void
  onUpgrade: (checkoutModalTarget: string) => void
  proPlusProductId: string
  user: User
  visible: boolean
}

const ProUpgradeModal = ({
  modalCopyObj,
  onClose,
  onUpgrade,
  proPlusProductId,
  user,
  visible,
}: ProUpgradeModalProps) => {
  const [proPrice, setProPrice] = useState("")
  const [loading, setLoading] = useState(false)

  const billingHeadsUp = user.isTrialing
    ? `${proPrice}Continue your trial with Pro+ to get access to queries, filter code, and multiple accounts.`
    : `${proPrice}We'll bill you now, minus a credit for any remaining days on your current plan.`

  useEffect(() => {
    if (!proPrice) {
      setLoading(true)
      loadSubscriptionWithRetries(1).then(() => {
        // Loading price or not in the modal, release the button at the end of loading with retries.
        setLoading(false)
      })
    }
  }, [])

  const loadSubscriptionWithRetries = (retries: number) => {
    return loadSubscriptionDetails("/billing/pro_upgrade_details")
      .then(detail => {
        setProPrice(`IFTTT Pro+ is billed at ${detail.formatted_pro_price} per ${detail.interval}. `)
      })
      .catch(error => {
        if (retries > 0) {
          return loadSubscriptionWithRetries(retries - 1)
        }
      })
  }

  const handleProUpgrade = async () => {
    setLoading(true)

    const { errors, user_subscription } = await updateSubscriptionPlan({ productId: proPlusProductId })
    setLoading(false)
    if (!errors?.length && user_subscription.plan_id == "pro") {
      window.appendFlash("Upgrade complete. Welcome to IFTTT Pro+!", "success")
      saveUserPermissions("pro", {
        filter_code: { permitted: true },
        multi_action: { permitted: true },
        queries: { permitted: true },
        multi_service_account: { permitted: true },
      })
      onUpgrade(user_subscription.plan_id)
    } else {
      const errorMessage =
        errors[0]?.message || "There was a problem upgrading your account. Please contact support for more information."
      window.appendFlash(errorMessage, "danger")
    }
    onClose()
  }

  const { title, editAppletWarning, copy } = modalCopyObj

  return (
    <div styleName="modal-container">
      <Modal show={visible} closeListener={onClose} includeCloseX={false}>
        <h2 className="mt0 mb0">{title}</h2>
        <p>
          {editAppletWarning}
          {copy}
        </p>
        <p>{billingHeadsUp}</p>
        <div className="action-buttons-container">
          <button className="button-tertiary" disabled={loading} onClick={handleProUpgrade}>
            Upgrade
          </button>
          <a className="link-standalone mt2" styleName="cancel-link" onClick={onClose}>
            Maybe later
          </a>
        </div>
      </Modal>
    </div>
  )
}

export default ProUpgradeModal
