import { Component } from "react"
import PropTypes from "prop-types"

import AnimationEvent from "app/scripts/animation_event"
import SuccessLottieAnimation from "./success_lottie_animation"

import classnames from "classnames"
import Fittty from "shared/components/fittty"
import colors from "foundation/_colors_export.scss?variables"
import { ctaCopyToConnect } from "../utils"

import "../connect_button/connect_button.scss"

export default class ConnectButton extends Component {
  state = {
    connected: false,
    playEnableAnimation: false,
    playHalfEnableAnimation: false,
    playMeterAnimation: false,
    playSuccessAnimation: false,
    ctaCopy: this.props.ctaCopy,
    showKnob: this.props.showKnob,
    knobBgColor: {
      backgroundColor: `#${this.props.mainService.brand_color}`,
    },
  }

  render() {
    const {
      playEnableAnimation,
      playMeterAnimation,
      playHalfEnableAnimation,
      playSuccessAnimation,
      connected,
      ctaCopy,
      showKnob,
      knobBgColor,
    } = this.state

    const { knobBgColorForDarkBrands, defaultBgColor, dynamicConfig, mainService } = this.props

    const buttonBgColor = showKnob
      ? defaultBgColor // displays in primaryTextColor
      : knobBgColor // or it's about the service hence takes the service color

    const showKnobStyle = showKnob ? { opacity: 1 } : { opacity: 0 }

    return (
      <div styleName="connect-button-outline">
        <div style={buttonBgColor} styleName="connect-button" onClick={this.handleClick}>
          {playSuccessAnimation && <SuccessLottieAnimation />}
          <div styleName="meter-container">
            <div
              id="progress-meter"
              styleName={classnames({
                meter: playMeterAnimation,
              })}
            />
          </div>
          <div
            id="connect-ball"
            styleName={classnames({
              "service-icon": true,
              "alt-brand-color": knobBgColorForDarkBrands,
              "slide-out": playEnableAnimation,
              "slide-out-from-middle": playHalfEnableAnimation,
              connected,
            })}
            style={{ ...knobBgColor, ...showKnobStyle }}
          >
            {dynamicConfig && (
              <img
                src={mainService.lrg_monochrome_image_url || mainService.lrg_variant_image_url}
                width="40"
                height="40"
                alt={mainService.name}
                title={mainService.name}
              />
            )}
          </div>
          <div
            styleName={classnames({
              "connect-cta": true,
              connected,
              "fade-out": playEnableAnimation,
              "opacity-1": !playEnableAnimation,
            })}
          >
            <div styleName={classnames({ "no-knob": playMeterAnimation })}>
              <Fittty maxSize={this.props.connectButtonMaxFontSize} minSize={14} text={ctaCopy} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleClick = event => {
    event.preventDefault()
    this.props.onConnectClick()
  }

  componentDidMount() {
    if (this.props.connected && !this.state.connected) {
      this.latch()
    } else {
      this.showConnectedState()
    }
  }

  handleKnobAnimationEnd = () => {
    this.meterElement = document.querySelector("#progress-meter")
    this.setState(
      {
        ctaCopy: `Connecting ${this.props.mainService.short_name}`,
        playMeterAnimation: true,
        playEnableAnimation: false,
        showKnob: false,
        connected: false,
      },
      () => {
        this.meterElement.addEventListener(AnimationEvent(), this.handleMeterAnimationEnd)
        this.knobElement.removeEventListener(AnimationEvent(), this.handleKnobAnimationEnd)
      }
    )
  }

  handleMeterAnimationEnd = () => {
    this.setState(
      {
        playMeterAnimation: false,
        playEnableAnimation: false,
        playSuccessAnimation: true,
        ctaCopy: "",
        showKnob: false,
      },
      () => {
        setTimeout(
          () =>
            this.setState({
              playSuccessAnimation: false,
              playHalfEnableAnimation: true,
              ctaCopy: "Connected",
              playMeterAnimation: false,
              showKnob: true,
              connected: true,
            }),
          this.props.successConnectionDelay
        )
      }
    )

    this.knobElement.removeEventListener(AnimationEvent(), this.handleMeterAnimationEnd)
  }

  showConnectedState = () => {
    this.setState({
      connected: true,
      playEnableAnimation: false,
      ctaCopy: "Connected",
    })
  }

  latch = () => {
    // don't play aninmations if the flow has not been started in this "session"
    if (this.props.connectClicked) {
      this.knobElement = document.querySelector("#connect-ball")
      this.setState(
        {
          connected: true,
          playEnableAnimation: true,
          showKnob: true,
          ctaCopy: ctaCopyToConnect(this.props.dynamicConfig && this.props.mainService.short_name),
        },
        () => {
          this.knobElement.addEventListener(AnimationEvent(), this.handleKnobAnimationEnd)
        }
      )
    } else {
      this.showConnectedState()
    }
  }
}

ConnectButton.propTypes = {
  connectClicked: PropTypes.bool.isRequired,
  mainService: PropTypes.object.isRequired,
  onConnectClick: PropTypes.func.isRequired,
  showKnob: PropTypes.bool,
  ctaCopy: PropTypes.string,
  connected: PropTypes.bool,
  dynamicConfig: PropTypes.bool,
  successConnectionDelay: PropTypes.number,
  defaultBgColor: PropTypes.object,
  knobBgColorForDarkBrands: PropTypes.bool,
  connectButtonMaxFontSize: PropTypes.number,
}

ConnectButton.defaultProps = {
  ctaCopy: "Connect",
  defaultBgColor: { backgroundColor: colors.primaryTextColor },
  successConnectionDelay: 1500,
  connected: false,
  connectButtonMaxFontSize: 30,
}
