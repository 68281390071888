import { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import { post } from "shared/lib/api"
import classNames from "classnames"

import checkmark from "images/components/checkmark.svg"
import "./promo_code_form.scss"

const PromoCodeForm = ({ disabled = false, initialPromoCode, onValidCodeApplied, plans }) => {
  const [promoCode, setPromoCode] = useState(initialPromoCode)
  const [showInput, setShowInput] = useState(!!initialPromoCode)
  const [couponName, setCouponName] = useState(null)
  const [loading, setLoading] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const inputRef = useRef()
  const toggleShow = () => setShowInput(!showInput)
  const discountApplied = !loading && couponName
  const promoCodeInvalid = !loading && invalid

  const validate = async () => {
    setLoading(true)
    try {
      post("/subscriptions/validate_promo_code", {
        code: promoCode,
        currency: plans.currency,
        month_price_in_cents: plans.monthlyPriceInCents,
        year_price_in_cents: plans.yearlyPriceInCents,
      })
        .then(result => {
          setCouponName(result)
          setInvalid(false)
          onValidCodeApplied(promoCode)
        })
        .catch(() => setInvalid(true))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus()
    } else {
      setPromoCode(null)
    }
  }, [showInput, inputRef])

  useEffect(() => {
    if (promoCode) {
      validate()
    }
  }, [])

  useEffect(() => {
    if (!initialPromoCode) {
      setPromoCode(null)
      setCouponName("")
      setLoading(false)
      inputRef.current?.focus()
    }
  }, [initialPromoCode])

  const promoCodeCopy = () => {
    if (plans.selectedInterval === "year" && couponName.year_price_copy) {
      return couponName.year_price_copy
    } else if (plans.selectedInterval === "month" && couponName.month_price_copy) {
      return couponName.month_price_copy
    } else {
      return "discount applied"
    }
  }

  return (
    <div
      styleName={classNames("promo-code-field", {
        success: discountApplied,
        error: promoCodeInvalid,
      })}
      className="field"
    >
      <div className="label">
        {!discountApplied && (
          <label>
            <a className="link-standalone" role="button" onClick={toggleShow}>
              Apply promo
            </a>
          </label>
        )}
      </div>
      <div
        className="input"
        styleName={classNames("input-group", {
          show: showInput,
        })}
      >
        <div styleName="input-line">
          <input
            aria-label="promo code"
            ref={inputRef}
            type="text"
            disabled={disabled || loading || couponName}
            value={promoCode || ""}
            onKeyDown={e => e.key === "Enter" && validate()}
            onChange={e => {
              setInvalid(false)
              setCouponName(null)
              setPromoCode(e.target.value)
            }}
          />
          <input
            disabled={disabled || loading || !promoCode || couponName}
            type="button"
            value="Apply"
            className="button-tertiary"
            onClick={validate}
          />
        </div>
        {discountApplied && (
          <div styleName="infobox">
            <span styleName="checkmark">{parse(checkmark)}</span>
            {promoCodeCopy()}
          </div>
        )}
        {promoCodeInvalid && <div styleName="infobox">Invalid promo code</div>}
      </div>
    </div>
  )
}

PromoCodeForm.propTypes = {
  disabled: PropTypes.bool,
  initialPromoCode: PropTypes.string,
  onValidCodeApplied: PropTypes.func.isRequired,
  plans: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    monthlyPriceInCents: PropTypes.number.isRequired,
    selectedInterval: PropTypes.oneOf(["month", "year"]).isRequired,
    yearlyPriceInCents: PropTypes.number.isRequired,
  }),
}

PromoCodeForm.defaultProps = {
  initialPromoCode: "",
}

export default PromoCodeForm
