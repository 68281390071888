import Switch from "shared/components/switch"
import colors from "foundation/_colors_export.scss?variables"

interface PushNotificationsProps {
  attribute: boolean
  label: string
  onChange: func
}

const PushNotifications = ({ label, onChange, attribute }: PushNotificationsProps) => {
  const styles = {
    circle: {
      onColor: "white",
      offColor: "white",
    },
    switch: {
      onColor: colors.primaryTextColor,
      offColor: colors.goodEvening,
    },
  }

  return (
    <>
      <div className="connection-notifications-switch">
        <p className="txt-body-3 info">{label}</p>
        <Switch
          size="normal"
          backgroundColor={styles.switch.offColor}
          backgroundColorEnabled={styles.switch.onColor}
          circleColor={styles.circle.onColor}
          circleColorEnabled={styles.circle.onColor}
          onChange={onChange}
          value={attribute}
        />
      </div>
    </>
  )
}

export default PushNotifications
