import { useState } from "react"
import PropTypes from "prop-types"
import Switch from "shared/components/switch"
import {
  liveConfigurationUpdate,
  parseGraphValidationErrors,
} from "shared/lib/graphql"
import colors from "foundation/_colors_export.scss?variables"

const Row = ({
  icon,
  title,
  slug,
  required,
  live_configurations,
  connectionEnabled,
  onConfigurationSwitchChange,
}) => {
  const liveConfiguration =
    live_configurations && live_configurations[0]
      ? live_configurations[0]
      : { disabled: true }
  const showToggle =
    connectionEnabled && (!required || liveConfiguration.disabled)

  return (
    <div className="proposition">
      <div className="feature-icon">
        <img
          src={`/value-prop-icons/${icon}.svg`}
          alt="value proposition"
          title="value proposition"
          loading="lazy"
          width="36"
          height="36"
        />
      </div>
      <div className="feature-name">{title}</div>
      {showToggle && (
        <Switch
          backgroundColor="#e5e5e7"
          circleColor={colors.primaryTextColor}
          backgroundColorEnabled="#34C759"
          circleColorEnabled="white"
          onChange={onConfigurationSwitchChange(slug)}
          value={!liveConfiguration.disabled}
        />
      )}
    </div>
  )
}

Row.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  live_configurations: PropTypes.array,
  connectionEnabled: PropTypes.bool.isRequired,
  onConfigurationSwitchChange: PropTypes.func.isRequired,
}

const toggleFeatureStatus = (
  configurations,
  connectionId,
  editPath,
  onConfigurationSwitchChange,
  [isSavingConfiguration, savingConfiguration]
) => {
  return slug => newValue => {
    if (isSavingConfiguration) {
      return null
    }

    savingConfiguration(true)

    const metadata = {
      configurations: configurations.map(config => ({
        slug: config.slug,
        disabled:
          config.slug === slug
            ? !newValue
            : config.live_configurations[0].disabled,
      })),
    }

    liveConfigurationUpdate(connectionId, metadata)
      .then(data => {
        if (data.errors) {
          window.location.assign(
            `${editPath}?error=${encodeURIComponent(
              JSON.stringify(parseGraphValidationErrors(data.errors))
            )}`
          )
        } else {
          onConfigurationSwitchChange(data.normalized_applet_configurations)
          window.appendFlash("Connection successfully updated")
        }
      })
      .catch(() =>
        window.appendFlash(
          "There was an error updating this connection. Please try agian later.",
          "danger"
        )
      )
      .finally(() => savingConfiguration(false))
  }
}

const ConfigurationDescriptions = ({
  applet,
  editPath,
  onConfigurationSwitchChange,
}) => {
  const [isSavingConfiguration, savingConfiguration] = useState(false)
  const configurationSwitchChange = appletConfig => config => {
    const liveConfigExists = !!(
      appletConfig.live_configurations &&
      appletConfig.live_configurations.length
    )

    if (liveConfigExists) {
      return toggleFeatureStatus(
        applet.configurations,
        applet.id,
        editPath,
        onConfigurationSwitchChange,
        [isSavingConfiguration, savingConfiguration]
      )(config)
    } else {
      return () => {
        window.appendFlash(
          "You'll need to configure this feature first.",
          "danger"
        )
        window.location.assign(editPath)
      }
    }
  }

  if (!(applet.configurations?.length > 0)) return null

  return (
    <div className="value-propositions">
      {applet.configurations.map((config, index) => (
        <Row
          key={index}
          connectionEnabled={applet.status === "enabled_for_user"}
          onConfigurationSwitchChange={configurationSwitchChange(config)}
          {...config}
        />
      ))}
    </div>
  )
}

ConfigurationDescriptions.propTypes = {
  applet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string,
    configurations: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        live_configurations: PropTypes.arrayOf(
          PropTypes.shape({
            disabled: PropTypes.bool,
          })
        ).isRequired,
      })
    ),
  }),
  editPath: PropTypes.string.isRequired,
  onConfigurationSwitchChange: PropTypes.func.isRequired,
}

export default ConfigurationDescriptions
