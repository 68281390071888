import { Component } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import iosSvg from "images/home/new_home/ios_download.svg"
import androidSvg from "images/home/new_home/google_play.svg"

import { verifyPhoneNumber } from "shared/lib/verify_phone_number"
import { sendDownloadLink } from "shared/lib/graphql"

import "./sms_form.scss"

export default class SMSForm extends Component {
  state = {
    phoneNumber: "",
    error: false,
  }

  handleFormChange = evt => {
    this.setState({
      phoneNumber: evt.target.value,
    })
  }

  flashMessage = message => {
    window.appendFlash(message)
    this.props.closeModal(true)
  }

  onSubmit = () => {
    if (!verifyPhoneNumber(this.state.phoneNumber)) {
      this.setState({
        error: true,
      })
      return
    }

    sendDownloadLink(
      this.state.phoneNumber,
      this.props.applet.applet_type,
      this.props.forApplet
    )
      .then(data => {
        let message
        if (
          data.errors &&
          data.errors[0].message === "User has already received an SMS"
        ) {
          message = "You have reached your SMS limit. Please try again later."
        } else if (data.status === "success") {
          message = "Message sent successfully."
        } else {
          message = "This message could not be sent."
        }

        this.flashMessage(message)
      })
      .catch(() => {
        this.flashMessage("This message could not be sent.")
      })
  }

  render() {
    return (
      <div styleName="sms-form">
        <div styleName="field">
          <div className="label">
            {this.state.error ? (
              <label styleName="error-message">
                Please enter a valid phone number.
              </label>
            ) : (
              <label>Enter your phone number</label>
            )}
          </div>
          <div className="input">
            <input
              name="phone"
              type="text"
              placeholder="+1 555 555 5555"
              onChange={evt => this.handleFormChange(evt)}
            />
          </div>
        </div>

        <button
          type="submit"
          className="button-secondary"
          styleName="sms-form-submit"
          onClick={this.onSubmit}
        >
          Send link
        </button>

        <div className="download-apps">
          <span
            styleName="svg-icon-mobile-alert"
            onClick={() => {
              window.open(this.props.appStorePath, "_blank")
            }}
          >
            {parse(iosSvg)}
          </span>
          <span
            styleName="svg-icon-mobile-alert"
            onClick={() => {
              window.open(this.props.googlePlayStorePath, "_blank")
            }}
          >
            {parse(androidSvg)}
          </span>
        </div>
      </div>
    )
  }
}

SMSForm.propTypes = {
  applet: PropTypes.shape({
    id: PropTypes.string,
    applet_type: PropTypes.string,
  }),
  appStorePath: PropTypes.string.isRequired,
  forApplet: PropTypes.bool,
  googlePlayStorePath: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
}

SMSForm.defaultProps = {
  forApplet: true,
}
