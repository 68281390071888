import { Component } from "react"
import PropTypes from "prop-types"

import ReconnectButton from "./reconnect_button"

export default class DisconnectedApplet extends Component {
  render() {
    return (
      <ReconnectButton
        animation={this.props.animation}
        connectButtonMaxFontSize={this.props.connectButtonMaxFontSize}
        connectClicked={this.props.connectClicked}
        connected={this.props.appletConnected}
        ctaCopy="Reconnect"
        disconnectClicked={this.props.disconnectClicked}
        knobBgColorForDarkBrands={this.props.knobBgColorForDarkBrands}
        mainService={this.props.mainService}
        onConnectClick={this.props.onConnectClick}
        dynamicConfig={this.props.dynamicConfig}
        services={this.props.services}
        updateAnimationCol={this.props.updateAnimationCol}
        showKnob
      />
    )
  }
}

DisconnectedApplet.propTypes = {
  animation: PropTypes.object.isRequired,
  applet: PropTypes.object.isRequired,
  appletConnected: PropTypes.bool,
  connectButtonMaxFontSize: PropTypes.number,
  connectClicked: PropTypes.bool.isRequired,
  disconnectClicked: PropTypes.bool.isRequired,
  knobBgColorForDarkBrands: PropTypes.bool,
  mainService: PropTypes.object.isRequired,
  onConnectClick: PropTypes.func.isRequired,
  dynamicConfig: PropTypes.bool,
  services: PropTypes.array.isRequired,
  updateAnimationCol: PropTypes.func.isRequired,
}
