import {
  setActivationUrl,
  accessGrantedClicked,
  updateNextToBeConnected,
  setLastServiceConnected,
  appletCreated,
  appletEnabled,
  appletIsSaving,
  connectClicked,
} from "../actions"

import { redirectToJoin } from "./user"
import { createApplet } from "./applet"

import Qs from "qs"
import isEmpty from "lodash/isEmpty"

import { checkConnectionAlreadyExists } from "shared/lib/graphql"

const initialState = {
  lastServiceConnected: null,
  nextToBeConnected: null,
  permissions: {},
  bulkActivationUrl: null,
  connectClicked: false,
  disconnectClicked: false,
  statusMessage: "WORKS WITH IFTTT",
  authenticationUrl: null,
}

// We won't bulk activate for v1 but something tells me we will end up doing
// them in bulk at some point (because less friction) so I'm leaving this here
export const buildActivationUrl = connections => (dispatch, getState) => {
  const { config } = getState()

  // IFE takes service_slugs as ?c[]=hue&c[]=alexa
  const slugsToParams = connections.reduce(
    (ac, conn) => {
      ac.c = [...ac.c, conn.module_name]
      return ac
    },
    { c: [] }
  )

  slugsToParams.return_to = config.returnTo

  const bulkActivationUrl = `${config.activationPath}?${Qs.stringify(
    slugsToParams,
    {
      arrayFormat: "brackets",
    }
  )}`

  dispatch(setActivationUrl(bulkActivationUrl))
}

export const grantAccess = service => (dispatch, getState) => {
  const { config, user } = getState()

  if (!user.signedIn) {
    dispatch(redirectToJoin())
  } else {
    const slugsToParams = { c: [service], return_to: config.returnTo }
    const url = `${config.activationPath}?${Qs.stringify(slugsToParams, {
      arrayFormat: "brackets",
    })}`

    // because of one-click-flow and its autoclick, redirecting the user to login
    // and to the service auth page can be fired almost at the same time so we test
    // if the user already has a session before sending them to IFE to connect a channel
    dispatch(accessGrantedClicked(url))
    window.location.assign(url) // no turbolinks here
  }
}

export const jumpToNextStep = () => (dispatch, getState) => {
  const {
    connections: { permissions },
    config,
  } = getState()

  const needsGrant = Object.values(permissions).filter(p => {
    return !p.connected
  })

  const connected = Object.values(permissions).filter(p => {
    return p.connected
  })

  const [lastConnected] = connected.slice(-1)

  dispatch(setLastServiceConnected(lastConnected))

  if (isEmpty(needsGrant)) {
    // connections with config will be saved with their stored fields
    !config.needsConfig
      ? dispatch(createApplet())
      : dispatch(checkConnectionExist())
  } else {
    dispatch(updateNextToBeConnected(needsGrant[0].module_name))
  }
}

const checkConnectionExist = () => (dispatch, getState) => {
  const {
    applet: { applet },
    config,
  } = getState()

  if (applet.config_type !== "dynamic") {
    window.location.assign(config.editPath)
  } else {
    // connection already existent in another IFTTT account?
    checkConnectionAlreadyExists(applet.id)
      .then(data => {
        if (data.connection_already_exists) {
          window.appendFlash(data.connection_already_exists, "danger")
          dispatch(appletIsSaving(false))
          dispatch(appletCreated(false))
          dispatch(appletEnabled(false))
          dispatch(connectClicked(false))
        } else {
          window.location.assign(config.editPath)
        }
      })
      .catch(() => {
        // if check query fails, allow users continue their flow
        window.location.assign(config.editPath)
      })
  }
}

export const requirementsMet = () => (dispatch, getState) => {
  const {
    connections: { permissions },
  } = getState()

  return isEmpty(
    Object.values(permissions).find(p => {
      return !p.connected
    })
  )
}

export default function connections(state = initialState, action) {
  switch (action.type) {
    case "SET_ACTIVATION_URL": {
      return {
        ...state,
        bulkActivationUrl: action.payload,
      }
    }

    case "UPDATE_NEXT_TO_BE_CONNECTED": {
      return {
        ...state,
        nextToBeConnected: action.payload,
      }
    }

    case "CONNECT_CLICKED": {
      return {
        ...state,
        connectClicked: action.payload,
      }
    }

    case "DISCONNECT_CLICKED": {
      return {
        ...state,
        disconnectClicked: action.payload,
      }
    }

    case "RESET_CONNECT_CLICKED": {
      return {
        ...state,
        connectClicked: false,
      }
    }

    case "ADD_CONNECTION_DEPENDENCY": {
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.id]: action.payload,
        },
      }
    }

    case "ADD_MAIN_SERVICE": {
      return {
        ...state,
        mainService: action.payload,
      }
    }

    case "ACCESS_GRANTED": {
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.id]: action.payload,
        },
      }
    }

    case "ACCESS_GRANT_CLICKED": {
      return {
        ...state,
        authenticationUrl: action.payload,
      }
    }

    case "LAST_SERVICE_CONNECTED": {
      return {
        ...state,
        lastServiceConnected: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
